@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* 
  * * Arita-dotum
  */
  @font-face {
    font-family: 'Arita-dotum';
    src: url('./fonts/arita4.0_t.woff2') format('woff2'),
      url('./fonts/arita4.0_t.woff') format('woff');
    font-weight: 100;
  }
  @font-face {
    font-family: 'Arita-dotum';
    src: url('./fonts/arita4.0_l.woff2') format('woff2'),
      url('./fonts/arita4.0_l.woff') format('woff');
    font-weight: 300;
  }
  @font-face {
    font-family: 'Arita-dotum';
    src: url('./fonts/arita4.0_m.woff2') format('woff2'),
      url('./fonts/arita4.0_m.woff') format('woff');
    font-weight: 500;
  }
  @font-face {
    font-family: 'Arita-dotum';
    src: url('./fonts/arita4.0_sb.woff2') format('woff2'),
      url('./fonts/arita4.0_sb.woff') format('woff');
    font-weight: 600;
  }
  @font-face {
    font-family: 'Arita-dotum';
    src: url('./fonts/arita4.0_b.woff2') format('woff2'),
      url('./fonts/arita4.0_b.woff') format('woff');
    font-weight: 700;
  }
  /* 
  * * Futura 
  */
  @font-face {
    font-family: 'Futura';
    src: url('./fonts/futura.ttf') format('ttf');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Futura';
    src: url('./fonts/Futura Bold font.ttf') format('ttf');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Futura';
    src: url('./fonts/Futura Extra Black font.ttf') format('ttf');
    font-weight: 800;
  }
  /* 
  * * Nanum_Myeongjo 
  */
  @font-face {
    font-family: 'Nanum Myeongjo';
    src: url('./fonts/NanumMyeongjoR.woff2') format('woff2'),
      url('./fonts/NanumMyeongjoR.woff') format('woff');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Nanum Myeongjo';
    src: url('./fonts/NanumMyeongjoB.woff2') format('woff2'),
      url('./fonts/NanumMyeongjoB.woff') format('woff');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Nanum Myeongjo';
    src: url('./fonts/NanumMyeongjoEB.woff2') format('woff2'),
      url('./fonts/NanumMyeongjoEB.woff') format('woff');
    font-weight: 800;
  }

  select {
    @apply outline-none focus:outline-none;
    /* text-align-last: center !important; */
  }
  button {
    @apply outline-none focus:outline-none;
  }
  input {
    @apply outline-none focus:outline-none;
    @apply ring-0 focus:ring-0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  input {
    -webkit-border-radius: 0;
    -webkit-box-shadow: none;
  }

  /**
  * ! scroll display
  **/
  /* ::-webkit-scrollbar {
    display: none;
  } */
}

@layer components {
  /* layer */
  .layer-1280 {
    @apply flex flex-col mx-auto;
    @apply w-full sm:max-w-screen-xl;
    @apply sm:w-320;
  }
  .layer-full {
    @apply flex flex-col;
    @apply w-full mx-auto;
    @apply sm:min-w-xl;
  }
  .container {
    @apply flex flex-col;
    @apply sm:pt-16 sm:px-12;
    @apply pt-10 px-6;
  }

  /* spinner */
  .spinner {
    @apply animate-spin opacity-40;
  }
  .spinner-btn {
    @apply animate-spin opacity-40 h-6 w-6;
  }

  /* button */
  .btn-text {
    @apply text-base font-semibold tracking-tight;
  }
  .btn {
    @apply flex justify-center items-center;
    @apply outline-none focus:outline-none;
    @apply cursor-pointer disabled:cursor-default;
  }
  .btn-main {
    @apply text-black;
    @apply bg-main hover:bg-main-dark disabled:bg-main-light;
    @apply border-2 border-main hover:border-main-dark disabled:border-main-light;
  }
  .btn-main-plain {
    @apply text-black;
    @apply bg-main hover:bg-main disabled:bg-main-light;
    @apply border-2 border-main disabled:border-main-light;
  }
  .btn-mint-plain {
    @apply text-white;
    @apply bg-mint hover:bg-mint disabled:bg-mint-light;
    @apply border-2 border-mint disabled:border-mint-light;
  }
  .btn-purple {
    @apply text-white hover:text-purple-50;
    @apply bg-purple-500 hover:bg-purple-700 disabled:bg-purple-300;
    @apply border-2 border-purple-500 disabled:border-purple-300;
  }
  .btn-blue {
    @apply text-white hover:text-blue-50;
    @apply bg-blue-500 hover:bg-blue-700 disabled:bg-blue-300;
    @apply border-2 border-blue-500 disabled:border-blue-300;
  }
  .btn-rose {
    @apply text-white;
    @apply bg-rose hover:bg-rose disabled:bg-rose;
    @apply border-2 border-rose disabled:border-rose;
  }
  .btn-red {
    @apply text-white hover:text-purple-50;
    @apply bg-red-500 hover:bg-red-700 disabled:bg-red-300;
    @apply border-2 border-red-500 disabled:border-red-300;
  }
  .btn-cobalt {
    @apply text-white;
    @apply bg-cobalt hover:bg-cobalt disabled:bg-silver-200;
    @apply border-2 border-cobalt disabled:border-silver-200;
  }
  .btn-white {
    @apply text-black;
    @apply bg-white hover:bg-white disabled:bg-silver-400;
    @apply border-2 border-white disabled:border-silver-400;
  }
  .btn-outline {
    @apply text-black;
    @apply bg-white hover:bg-white disabled:bg-white;
    @apply border border-silver-200 disabled:border-silver-200;
  }
  .btn-outline-400 {
    @apply text-black;
    @apply bg-white hover:bg-white disabled:bg-white;
    @apply border border-silver-400 disabled:border-silver-400;
  }
  .btn-outline-gray-200 {
    @apply text-black;
    @apply bg-white hover:bg-white disabled:bg-white;
    @apply border border-gray-200 disabled:border-gray-200;
  }
  .btn-outline-red {
    @apply text-rose;
    @apply bg-white hover:bg-white disabled:bg-white;
    @apply border border-rose disabled:border-rose;
  }
  .btn-outline-blue {
    @apply text-blue-700;
    @apply bg-white hover:bg-white disabled:bg-white;
    @apply border border-blue-500 disabled:border-blue-300;
  }
  .btn-outline-main {
    @apply text-main-dark;
    @apply bg-white hover:bg-white disabled:bg-white;
    @apply border border-main disabled:border-main-light;
  }
  .btn-outline-yellow {
    @apply text-yellow-600;
    @apply bg-white hover:bg-white disabled:bg-white;
    @apply border border-yellow-500 disabled:border-yellow-300;
  }
  .btn-outline-hover-main {
    @apply text-black hover:text-main-dark;
    @apply bg-white hover:bg-white disabled:bg-white;
    @apply border border-gray-300 hover:border-main-dark disabled:border-gray-200;
  }
  .word-break-keep-all {
    word-break: keep-all;
  }
  .select-chevron-none {
    appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

@layer utilities {
}

.tui-editor-contents > table {
  @apply w-full table-fixed break-all;
}

.mobile-classlog-calendar-left {
  left: calc((100vw - 172.5px) / 2);
}

/* [role='menu']::-webkit-scrollbar-track {
  width: 10px;
  margin: 2px;
} */
[role='menu']::-webkit-scrollbar {
  width: 6px;
  height: auto;
}
[role='menu']::-webkit-scrollbar-thumb {
  background: #eeeeee;
  border-radius: 9999px;
}

[role='listbox']::-webkit-scrollbar {
  width: 6px;
  height: auto;
}
[role='listbox']::-webkit-scrollbar-thumb {
  background: #eeeeee;
  border-radius: 9999px;
}
* {
  @apply antialiased;
  -webkit-tap-highlight-color: transparent;
}

[role='schedule-hour'],
[role='schedule-minute'] {
  appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: none;
}
