@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  [multiple]:focus,
  [type='date']:focus,
  [type='email']:focus,
  [type='month']:focus,
  [type='number']:focus,
  [type='password']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='text']:focus,
  [type='time']:focus,
  [type='url']:focus,
  select:focus,
  textarea:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 1px #2563eb, 0 0 transparent;
    border-color: #2563eb;
  }

  [type='checkbox']:focus,
  [type='radio']:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 0 2px #fff, 0 0 0 0 4px #2563eb, 0 0 transparent;
  }

  .btn-main {
    color: rgba(0, 0, 0, 1);
    background-color: rgba(255, 210, 43, 1);
  }

  .btn-main:hover {
    background-color: rgba(245, 197, 16, 1);
  }

  .btn-main:disabled {
    background-color: rgba(255, 238, 179, 1);
  }

  .btn-main {
    border-color: rgba(255, 210, 43, 1);
  }

  .btn-main:hover {
    border-color: rgba(245, 197, 16, 1);
  }

  .btn-main:disabled {
    border-color: rgba(255, 238, 179, 1);
  }

  .btn-main-plain {
    color: rgba(0, 0, 0, 1);
  }

  .btn-main-plain,
  .btn-main-plain:hover {
    background-color: rgba(255, 210, 43, 1);
  }

  .btn-main-plain:disabled {
    background-color: rgba(255, 238, 179, 1);
  }

  .btn-main-plain {
    border-color: rgba(255, 210, 43, 1);
  }

  .btn-main-plain:disabled {
    border-color: rgba(255, 238, 179, 1);
  }

  .btn-rose {
    color: rgba(255, 255, 255, 1);
  }

  .btn-rose,
  .btn-rose:disabled,
  .btn-rose:hover {
    background-color: rgba(204, 26, 12, 1);
  }

  .btn-rose,
  .btn-rose:disabled {
    border-color: rgba(204, 26, 12, 1);
  }

  .btn-cobalt {
    color: rgba(255, 255, 255, 1);
  }

  .btn-cobalt,
  .btn-cobalt:hover {
    background-color: rgba(38, 125, 249, 1);
  }

  .btn-cobalt:disabled {
    background-color: rgba(214, 214, 214, 1);
  }

  .btn-cobalt {
    border-color: rgba(38, 125, 249, 1);
  }

  .btn-cobalt:disabled {
    border-color: rgba(214, 214, 214, 1);
  }

  .btn-cobalt {
    border-width: 2px;
  }

  .btn-white {
    color: rgba(0, 0, 0, 1);
  }

  .btn-white,
  .btn-white:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  .btn-white:disabled {
    background-color: rgba(115, 115, 115, 1);
  }

  .btn-white {
    border-color: rgba(255, 255, 255, 1);
  }

  .btn-white:disabled {
    border-color: rgba(115, 115, 115, 1);
  }

  .btn-outline {
    color: rgba(0, 0, 0, 1);
  }

  .btn-outline,
  .btn-outline:disabled,
  .btn-outline:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  .btn-outline,
  .btn-outline:disabled {
    border-color: rgba(214, 214, 214, 1);
  }

  .btn-outline-400 {
    color: rgba(0, 0, 0, 1);
  }

  .btn-outline-400,
  .btn-outline-400:disabled,
  .btn-outline-400:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  .btn-outline-400,
  .btn-outline-400:disabled {
    border-color: rgba(115, 115, 115, 1);
  }

  .btn-outline-red {
    color: rgba(204, 26, 12, 1);
  }

  .btn-outline-red,
  .btn-outline-red:disabled,
  .btn-outline-red:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  .btn-outline-red,
  .btn-outline-red:disabled {
    border-color: rgba(204, 26, 12, 1);
  }

  .btn-outline-red {
    border-width: 1px;
  }

  .btn-outline-hover-main {
    color: rgba(0, 0, 0, 1);
  }

  .btn-outline-hover-main:hover {
    color: rgba(245, 197, 16, 1);
  }

  .btn-outline-hover-main,
  .btn-outline-hover-main:disabled,
  .btn-outline-hover-main:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  .btn-outline-hover-main {
    border-color: rgba(209, 213, 219, 1);
  }

  .btn-outline-hover-main:hover {
    border-color: rgba(245, 197, 16, 1);
  }

  .btn-outline-hover-main:disabled {
    border-color: rgba(229, 231, 235, 1);
  }

  .btn-outline-hover-main {
    border-width: 1px;
  }

  .word-break-keep-all {
    word-break: keep-all;
  }

  .divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    border-top-width: calc(2px * (1 - 0));
    border-bottom-width: calc(2px * 0);
  }

  .divide-y > :not([hidden]) ~ :not([hidden]) {
    border-top-width: calc(1px * (1 - 0));
    border-bottom-width: calc(1px * 0);
  }

  .divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(249, 250, 251, 1);
  }

  .divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    border-color: rgba(243, 244, 246, 1);
  }

  .bg-black {
    background-color: rgba(0, 0, 0, 1);
  }

  .bg-white {
    background-color: rgba(255, 255, 255, 1);
  }

  .bg-gray-50 {
    background-color: rgba(249, 250, 251, 1);
  }

  .bg-gray-100 {
    background-color: rgba(243, 244, 246, 1);
  }

  .bg-gray-200 {
    background-color: rgba(229, 231, 235, 1);
  }

  .bg-gray-300 {
    background-color: rgba(209, 213, 219, 1);
  }

  .bg-gray-400 {
    background-color: rgba(156, 163, 175, 1);
  }

  .bg-gray-500 {
    background-color: rgba(107, 114, 128, 1);
  }

  .bg-red-300 {
    background-color: rgba(252, 165, 165, 1);
  }

  .bg-red-400 {
    background-color: rgba(248, 113, 113, 1);
  }

  .bg-red-500 {
    background-color: rgba(239, 68, 68, 1);
  }

  .bg-red-600 {
    background-color: rgba(220, 38, 38, 1);
  }

  .bg-yellow-100 {
    background-color: rgba(254, 243, 199, 1);
  }

  .bg-yellow-200 {
    background-color: rgba(253, 230, 138, 1);
  }

  .bg-blue-400 {
    background-color: rgba(96, 165, 250, 1);
  }

  .bg-blue-500 {
    background-color: rgba(59, 130, 246, 1);
  }

  .bg-blue-600 {
    background-color: rgba(37, 99, 235, 1);
  }

  .bg-indigo-600 {
    background-color: rgba(79, 70, 229, 1);
  }

  .bg-application-blue {
    background-color: rgba(212, 227, 246, 1);
  }

  .bg-application-red {
    background-color: rgba(255, 214, 217, 1);
  }

  .bg-about-navy {
    background-color: rgba(32, 42, 74, 1);
  }

  .bg-info-yellow {
    background-color: rgba(250, 199, 1, 1);
  }

  .bg-info-green {
    background-color: rgba(0, 184, 143, 1);
  }

  .bg-info-blue {
    background-color: rgba(0, 166, 193, 1);
  }

  .bg-main-light {
    background-color: rgba(255, 238, 179, 1);
  }

  .bg-main {
    background-color: rgba(255, 210, 43, 1);
  }

  .bg-main-dark {
    background-color: rgba(245, 197, 16, 1);
  }

  .bg-rose {
    background-color: rgba(204, 26, 12, 1);
  }

  .bg-mint {
    background-color: rgba(98, 194, 206, 1);
  }

  .bg-silver-100 {
    background-color: rgba(243, 243, 243, 1);
  }

  .bg-silver-300 {
    background-color: rgba(177, 177, 177, 1);
  }

  .bg-silver-400 {
    background-color: rgba(115, 115, 115, 1);
  }

  .bg-ivory {
    background-color: rgba(254, 251, 240, 1);
  }

  .group:hover .group-hover\:bg-gray-100 {
    background-color: rgba(243, 244, 246, 1);
  }

  .group:hover .group-hover\:bg-gray-300 {
    background-color: rgba(209, 213, 219, 1);
  }

  .hover\:bg-white:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  .hover\:bg-gray-100:hover {
    background-color: rgba(243, 244, 246, 1);
  }

  .hover\:bg-gray-500:hover {
    background-color: rgba(107, 114, 128, 1);
  }

  .hover\:bg-red-500:hover {
    background-color: rgba(239, 68, 68, 1);
  }

  .hover\:bg-red-700:hover {
    background-color: rgba(185, 28, 28, 1);
  }

  .hover\:bg-yellow-50:hover {
    background-color: rgba(255, 251, 235, 1);
  }

  .hover\:bg-blue-500:hover {
    background-color: rgba(59, 130, 246, 1);
  }

  .hover\:bg-blue-700:hover {
    background-color: rgba(29, 78, 216, 1);
  }

  .hover\:bg-blue-900:hover {
    background-color: rgba(30, 58, 138, 1);
  }

  .hover\:bg-main-light:hover {
    background-color: rgba(255, 238, 179, 1);
  }

  .hover\:bg-main-dark:hover {
    background-color: rgba(245, 197, 16, 1);
  }

  .hover\:bg-silver-100:hover {
    background-color: rgba(243, 243, 243, 1);
  }

  .disabled\:bg-gray-200:disabled {
    background-color: rgba(229, 231, 235, 1);
  }

  .bg-opacity-40 {
    opacity: 0.4;
  }

  .bg-opacity-75 {
    opacity: 0.75;
  }

  .border-black {
    border-color: rgba(0, 0, 0, 1);
  }

  .border-white {
    border-color: rgba(255, 255, 255, 1);
  }

  .border-gray-100 {
    border-color: rgba(243, 244, 246, 1);
  }

  .border-gray-200 {
    border-color: rgba(229, 231, 235, 1);
  }

  .border-gray-300 {
    border-color: rgba(209, 213, 219, 1);
  }

  .border-gray-400 {
    border-color: rgba(156, 163, 175, 1);
  }

  .border-gray-600 {
    border-color: rgba(75, 85, 99, 1);
  }

  .border-gray-700 {
    border-color: rgba(55, 65, 81, 1);
  }

  .border-red-300 {
    border-color: rgba(252, 165, 165, 1);
  }

  .border-red-500 {
    border-color: rgba(239, 68, 68, 1);
  }

  .border-red-600 {
    border-color: rgba(220, 38, 38, 1);
  }

  .border-green-500 {
    border-color: rgba(16, 185, 129, 1);
  }

  .border-blue-500 {
    border-color: rgba(59, 130, 246, 1);
  }

  .border-blue-600 {
    border-color: rgba(37, 99, 235, 1);
  }

  .border-about-navy {
    border-color: rgba(32, 42, 74, 1);
  }

  .border-about-gold {
    border-color: rgba(180, 160, 138, 1);
  }

  .border-info-yellow {
    border-color: rgba(250, 199, 1, 1);
  }

  .border-info-green {
    border-color: rgba(0, 184, 143, 1);
  }

  .border-info-blue {
    border-color: rgba(0, 166, 193, 1);
  }

  .border-main {
    border-color: rgba(255, 210, 43, 1);
  }

  .border-main-dark {
    border-color: rgba(245, 197, 16, 1);
  }

  .border-rose {
    border-color: rgba(204, 26, 12, 1);
  }

  .border-qPink {
    border-color: rgba(227, 198, 215, 1);
  }

  .border-mint {
    border-color: rgba(98, 194, 206, 1);
  }

  .border-silver-200 {
    border-color: rgba(214, 214, 214, 1);
  }

  .border-silver-300 {
    border-color: rgba(177, 177, 177, 1);
  }

  .border-silver-400 {
    border-color: rgba(115, 115, 115, 1);
  }

  .hover\:border-black:hover {
    border-color: rgba(0, 0, 0, 1);
  }

  .hover\:border-gray-400:hover {
    border-color: rgba(156, 163, 175, 1);
  }

  .hover\:border-main:hover {
    border-color: rgba(255, 210, 43, 1);
  }

  .hover\:border-main-dark:hover {
    border-color: rgba(245, 197, 16, 1);
  }

  .hover\:border-rose:hover {
    border-color: rgba(204, 26, 12, 1);
  }

  .hover\:border-silver-200:hover {
    border-color: rgba(214, 214, 214, 1);
  }

  .focus\:border-gray-200:focus {
    border-color: rgba(229, 231, 235, 1);
  }

  .focus\:border-gray-300:focus {
    border-color: rgba(209, 213, 219, 1);
  }

  .focus\:border-indigo-500:focus {
    border-color: rgba(99, 102, 241, 1);
  }

  .focus\:border-main:focus {
    border-color: rgba(255, 210, 43, 1);
  }

  .focus\:border-main-dark:focus {
    border-color: rgba(245, 197, 16, 1);
  }

  .focus\:border-rose:focus {
    border-color: rgba(204, 26, 12, 1);
  }

  .disabled\:border-gray-200:disabled {
    border-color: rgba(229, 231, 235, 1);
  }

  .disabled\:border-gray-300:disabled {
    border-color: rgba(209, 213, 219, 1);
  }

  .disabled\:border-silver-200:disabled {
    border-color: rgba(214, 214, 214, 1);
  }

  .placeholder-gray-300::-webkit-input-placeholder {
    color: rgba(209, 213, 219, 1);
  }

  .placeholder-gray-300:-ms-input-placeholder {
    color: rgba(209, 213, 219, 1);
  }

  .placeholder-gray-300::placeholder {
    color: rgba(209, 213, 219, 1);
  }

  .placeholder-gray-400::-webkit-input-placeholder {
    color: rgba(156, 163, 175, 1);
  }

  .placeholder-gray-400:-ms-input-placeholder {
    color: rgba(156, 163, 175, 1);
  }

  .placeholder-gray-400::placeholder {
    color: rgba(156, 163, 175, 1);
  }

  .placeholder-silver-300::-webkit-input-placeholder {
    color: rgba(177, 177, 177, 1);
  }

  .placeholder-silver-300:-ms-input-placeholder {
    color: rgba(177, 177, 177, 1);
  }

  .placeholder-silver-300::placeholder {
    color: rgba(177, 177, 177, 1);
  }

  * {
    box-shadow: 0 0 transparent;
  }

  .shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .shadow,
  .shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .shadow-lg,
  .shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .ring-0 {
    /* box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 0px rgba(59, 130, 246, 0.5),
      0 0 transparent; */
  }

  .ring-0,
  .ring-1 {
    /* box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 1px rgba(59, 130, 246, 0.5),
      0 0 transparent; */
  }

  .ring-1 {
    /* box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 1px rgba(59, 130, 246, 0.5),
      0 0 transparent; */
  }

  .ring-2 {
    /* box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 2px rgba(59, 130, 246, 0.5),
      0 0 transparent; */
  }

  .hover\:ring-1:hover,
  .ring-2 {
    /* box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 1px rgba(59, 130, 246, 0.5),
      0 0 transparent; */
  }

  .hover\:ring-1:hover {
    /* box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 1px rgba(59, 130, 246, 0.5),
      0 0 transparent; */
  }

  .focus\:ring-0:focus {
    /* box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 0px rgba(59, 130, 246, 0.5),
      0 0 transparent; */
  }

  .focus\:ring-0:focus,
  .focus\:ring-2:focus {
    /* box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 2px rgba(59, 130, 246, 0.5),
      0 0 transparent; */
  }

  .focus\:ring-2:focus {
    /* box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 2px rgba(59, 130, 246, 0.5),
      0 0 transparent; */
  }

  .focus\:ring-offset-2:focus,
  .ring-offset-2 {
    /* box-shadow: 0 0 0 0 2px #fff, 0 0 0 0 1px rgba(59, 130, 246, 0.5),
      0 0 transparent; */
  }

  .ring-transparent {
    /* box-shadow: 0 0 0 0 0px #fff, 0 0 0 0 1px transparent, 0 0 transparent; */
    color: transparent;
  }

  .ring-black {
    /* box-shadow: 0 0 0 0 2px #fff, 0 0 0 0 1px rgba(0, 0, 0, 1), 0 0 transparent; */
    color: rgba(0, 0, 0, 1);
  }

  .ring-gray-300 {
    color: rgba(209, 213, 219, 1);
  }

  .ring-blue-500 {
    color: rgba(59, 130, 246, 1);
  }

  .ring-blue-600 {
    color: rgba(37, 99, 235, 1);
  }

  .ring-main {
    color: rgba(255, 210, 43, 1);
  }

  .ring-main-dark {
    color: rgba(245, 197, 16, 1);
  }

  .hover\:ring-main:hover {
    color: rgba(255, 210, 43, 1);
  }

  .hover\:ring-main-dark:hover {
    color: rgba(245, 197, 16, 1);
  }

  .focus\:ring-transparent:focus {
    color: transparent;
  }

  .focus\:ring-white:focus {
    color: rgba(255, 255, 255, 1);
  }

  .focus\:ring-red-500:focus {
    color: rgba(239, 68, 68, 1);
  }

  .focus\:ring-blue-600:focus {
    color: rgba(37, 99, 235, 1);
  }

  .focus\:ring-indigo-500:focus {
    color: rgba(99, 102, 241, 1);
  }

  .focus\:ring-main-dark:focus {
    color: rgba(245, 197, 16, 1);
  }

  .ring-opacity-5 {
    /* opacity: 0.05; */
  }
  .text-black {
    color: rgba(0, 0, 0, 1);
  }

  .text-white {
    color: rgba(255, 255, 255, 1);
  }

  .text-gray-50 {
    color: rgba(249, 250, 251, 1);
  }

  .text-gray-400 {
    color: rgba(156, 163, 175, 1);
  }

  .text-gray-500 {
    color: rgba(107, 114, 128, 1);
  }

  .text-gray-600 {
    color: rgba(75, 85, 99, 1);
  }

  .text-gray-700 {
    color: rgba(55, 65, 81, 1);
  }

  .text-gray-900 {
    color: rgba(17, 24, 39, 1);
  }

  .text-red-50 {
    color: rgba(254, 242, 242, 1);
  }

  .text-red-500 {
    color: rgba(239, 68, 68, 1);
  }

  .text-red-600 {
    color: rgba(220, 38, 38, 1);
  }

  .text-green-600 {
    color: rgba(5, 150, 105, 1);
  }

  .text-blue-500 {
    color: rgba(59, 130, 246, 1);
  }

  .text-blue-600 {
    color: rgba(37, 99, 235, 1);
  }

  .text-blue-700 {
    color: rgba(29, 78, 216, 1);
  }

  .text-indigo-600 {
    color: rgba(79, 70, 229, 1);
  }

  .text-about-navy {
    color: rgba(32, 42, 74, 1);
  }

  .text-main-light {
    color: rgba(255, 238, 179, 1);
  }

  .text-main {
    color: rgba(255, 210, 43, 1);
  }

  .text-main-dark {
    color: rgba(245, 197, 16, 1);
  }

  .text-rose {
    color: rgba(204, 26, 12, 1);
  }

  .text-cobalt {
    color: rgba(38, 125, 249, 1);
  }

  .text-silver-400 {
    color: rgba(115, 115, 115, 1);
  }

  .group:hover .group-hover\:text-gray-900 {
    color: rgba(17, 24, 39, 1);
  }

  .group:hover .group-hover\:text-blue-500 {
    color: rgba(59, 130, 246, 1);
  }

  .hover\:text-black:hover {
    color: rgba(0, 0, 0, 1);
  }

  .hover\:text-gray-600:hover {
    color: rgba(75, 85, 99, 1);
  }

  .hover\:text-gray-800:hover {
    color: rgba(31, 41, 55, 1);
  }

  .hover\:text-gray-900:hover {
    color: rgba(17, 24, 39, 1);
  }

  .hover\:text-blue-700:hover {
    color: rgba(29, 78, 216, 1);
  }

  .disabled\:text-gray-300:disabled {
    color: rgba(209, 213, 219, 1);
  }

  .disabled\:text-gray-500:disabled {
    color: rgba(107, 114, 128, 1);
  }

  .transform {
    -webkit-transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
      scaleX(1) scaleY(1);
    transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1)
      scaleY(1);
  }

  .scale-95 {
    -webkit-transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
      scaleX(0.95) scaleY(0.95);
    transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
      scaleX(0.95) scaleY(0.95);
  }

  .scale-100 {
    -webkit-transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
      scaleX(1) scaleY(1);
    transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1)
      scaleY(1);
  }

  .rotate-45 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .translate-x-0 {
    -webkit-transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
      scaleX(1) scaleY(1);
    transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1)
      scaleY(1);
  }

  .translate-x-2 {
    -webkit-transform: translateX(0.5rem) translateY(0) rotate(0) skewX(0)
      skewY(0) scaleX(1) scaleY(1);
    transform: translateX(0.5rem) translateY(0) rotate(0) skewX(0) skewY(0)
      scaleX(1) scaleY(1);
  }

  .translate-x-5 {
    -webkit-transform: translateX(1.25rem) translateY(0) rotate(0) skewX(0)
      skewY(0) scaleX(1) scaleY(1);
    transform: translateX(1.25rem) translateY(0) rotate(0) skewX(0) skewY(0)
      scaleX(1) scaleY(1);
  }

  .translate-x-10 {
    /* -webkit-transform: translateX(2.5rem) translateY(0) rotate(0) skewX(0)
      skewY(0) scaleX(1) scaleY(1);
    transform: translateX(2.5rem) translateY(0) rotate(0) skewX(0) skewY(0)
      scaleX(1) scaleY(1); */
  }

  .translate-y-0 {
    -webkit-transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
      scaleX(1) scaleY(1);
    transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1)
      scaleY(1);
  }

  .translate-y-1 {
    -webkit-transform: translateX(0) translateY(0.25rem) rotate(0) skewX(0)
      skewY(0) scaleX(1) scaleY(1);
    transform: translateX(0) translateY(0.25rem) rotate(0) skewX(0) skewY(0)
      scaleX(1) scaleY(1);
  }

  .translate-y-4 {
    -webkit-transform: translateX(0) translateY(1rem) rotate(0) skewX(0)
      skewY(0) scaleX(1) scaleY(1);
    transform: translateX(0) translateY(1rem) rotate(0) skewX(0) skewY(0)
      scaleX(1) scaleY(1);
  }

  .filter {
    -webkit-filter: 0 0 0 0 0 0 0 0 0;
    filter: 0 0 0 0 0 0 0 0 0;
  }

  @media (min-width: 640px) {
    .sm\:border-gray-200 {
      border-color: rgba(229, 231, 235, 1);
    }

    .sm\:scale-95 {
      -webkit-transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
        scaleX(0.95) scaleY(0.95);
      transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
        scaleX(0.95) scaleY(0.95);
    }

    .sm\:scale-100 {
      -webkit-transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
        scaleX(1) scaleY(1);
      transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
        scaleX(1) scaleY(1);
    }

    .sm\:translate-x-0 {
      -webkit-transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
        scaleX(1) scaleY(1);
      transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
        scaleX(1) scaleY(1);
    }

    .sm\:translate-y-0 {
      -webkit-transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
        scaleX(1) scaleY(1);
      transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0)
        scaleX(1) scaleY(1);
    }
  }

  .react-calendar__tile:disabled,
  .react-calendar__tile:enabled:focus,
  .react-calendar__tile:enabled:hover {
    background-color: rgba(229, 231, 235, 1);
  }

  .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 9999px;
  }

  .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day:focus,
  .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day:hover {
    background-color: rgba(229, 231, 235, 1);
    border-radius: 9999px;
  }

  .react-calendar__tile--hasActive,
  .react-calendar__tile--hasActive:enabled:focus,
  .react-calendar__tile--hasActive:enabled:hover {
    background-color: rgba(191, 219, 254, 1);
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar__tile--active:enabled:hover {
    background-color: rgba(245, 197, 16, 1) !important;
  }
}
